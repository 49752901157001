import { graphql, useStaticQuery } from 'gatsby';
import React, { FunctionComponent, useState } from 'react';
import Masonry from 'react-masonry-css';
import { WpGraphQl, WpGraphQl_Post } from '../../../graphql-types';
import PostCard from './post-card.component';
import styles from './post-list.component.module.scss';
import PostToolbar from './post-toolbar.component';

const PostList: FunctionComponent = () => {
  const breakpointColumns = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1
  };

  const data: WpGraphQl = useStaticQuery(graphql`
  {
    wpcontent {
      posts {
        nodes {
          id
          title
          slug
          date
          excerpt
          featuredImage {
            node {
              sourceUrl
              imageFile {
                childImageSharp {
                  fixed {
                    base64
                    width
                    height
                    src
                    srcSet
                  }
                }
              }
            }
          }
          categories {
            nodes {
              name
            }
          }
        }
      }
    }
 }`).wpcontent;
  const [posts, setPosts] = useState<WpGraphQl_Post[]>(
    data.posts?.nodes?.filter(Boolean).map(post => post!) ?? []
  );

  const matchTerm = (value: string, term: string): boolean => value.toLowerCase().indexOf(term) !== -1;

  const filterPosts = (term: string) => {
    setPosts(posts.filter(p => {
      return matchTerm(p.title!, term) ||
        p.categories?.nodes?.some(c => matchTerm(c!.name!, term))
    }));
  };

  return (
    <div className={styles.postList} id={'post-list'}>
      <PostToolbar onSearchTermChange={filterPosts}/>
      <Masonry
        breakpointCols={breakpointColumns}
        className={styles.list}
        columnClassName={styles.gridColumn}>
        {posts.map(post => (
          <PostCard key={post.id} post={post}/>
        ))}
      </Masonry>
    </div>
  );
};

export default PostList;