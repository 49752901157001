import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { ChangeEvent, FunctionComponent, useCallback, useState } from 'react';
import styles from './search-bar.component.module.scss';

type SearchBarProps = {
  debounceMs?: number,
  onChange: (value: string) => void
}

const SearchBar: FunctionComponent<SearchBarProps> = ({ debounceMs, onChange }) => {
  const [value, setValue] = useState('');

  const onChangedDebounced = useCallback(
    _.debounce(nextValue => onChange(nextValue), debounceMs || 500),
    []
  );

  const updateValue = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChangedDebounced(e.target.value);
  };

  const activeClassName = value ? styles.active : '';

  return (
    <div className={styles.searchBarContainer}>
      <form action="" className={`${styles.searchBar} ${activeClassName}`}>
        <input type="search" className={styles.search} placeholder={'Search'} onChange={updateValue}/>
        <FontAwesomeIcon icon={faSearch} className={styles.icon}/>
      </form>
    </div>
  );
};

export default SearchBar;