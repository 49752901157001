import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import About from '../../components/about/about.component';
import Contact from '../../components/contact/contact.component';
import Header3D from '../../components/header/header-3d/header-3d.component';
import Logo from '../../components/header/logo/logo.component';
import Navbar from '../../components/header/navbar/navbar.component';
import PostList from '../../components/posts/post-list.component';
import Config from '../../config';
import globalStyles from '../../styles/global.module.scss';
import styles from './index.page.module.scss';

const IndexPage: FunctionComponent = () => {
  return (
    <div className={styles.container}>
      <Helmet>
        <title>{Config.siteTitle}</title>
      </Helmet>
      <Navbar/>
      <Header3D/>
      <Logo/>
      <div className={globalStyles.contentContainer}>
        <About/>
      </div>
      <div className={`${globalStyles.contentContainer} ${globalStyles.main}`}>
        <div className={globalStyles.content}>
          <h1 id={'in-my-words'}>In my words</h1>
          <PostList/>
        </div>
      </div>
      <div className={`${globalStyles.contentContainer} ${globalStyles.diagonalBackground}`}>
        <Contact/>
      </div>
    </div>
  );
};

export default IndexPage;
