import React, { FunctionComponent } from 'react';
import Config from '../../config';
import SearchBar from '../search/search-bar.component';
import styles from './post-toolbar.component.module.scss';

type PostToolbarProps = {
  onSearchTermChange: (term: string) => void
}

const PostToolbar: FunctionComponent<PostToolbarProps> = ({ onSearchTermChange }) => {
  return (
    <div className={styles.postToolbar}>
      <SearchBar onChange={onSearchTermChange} debounceMs={Config.posts.filter.debounceMs}/>
    </div>
  );
};

export default PostToolbar;