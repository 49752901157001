import React, { FunctionComponent } from 'react';
import globalStyles from '../../styles/global.module.scss';
import FadeInSection from '../fade-in-section/fade-in-section.component';
import styles from './about.component.module.scss';

const About: FunctionComponent = () => {
  const fadeSpeed = 3;

  return (
    <div className={`${globalStyles.content} ${styles.about}`} id={'about'}>
      <div className={globalStyles.container10}>
        <FadeInSection speed={fadeSpeed}>
          <h1>Hello, I am Philipp.</h1>
        </FadeInSection>
        <FadeInSection speed={fadeSpeed}>
          <p>
            I’m an independent game developer coming from the outskirts of the German-French border.<br/>
            I a world of growing uncertainty and still on the way to find what life means to me,<br/>
            developing games has become an outlet, a hobby and a passion.
          </p>
        </FadeInSection>
        <FadeInSection speed={fadeSpeed}>
          <p>
            I tend to blog, sometimes. mostly on gamedev but also on life’s other mysteries<br/>
            if that’s what you’re into.
          </p>
        </FadeInSection>
      </div>
    </div>
  );
};

export default About;
