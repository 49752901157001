import React, { FunctionComponent, Suspense } from 'react';
import { Camera, Canvas } from 'react-three-fiber';
import { Controls, withControls } from 'react-three-gui';
import { Color, PCFSoftShadowMap, WebGLRenderer } from 'three';
import BasicModel from './basic-model.component';

const MyCanvas = withControls(Canvas);

const Header3D: FunctionComponent = () => {
  return (
    <Controls.Provider>
      <MyCanvas
        camera={{ position: [-3, 4.2, 15], fov: 30 }}
        onCreated={({ gl, camera }: {gl: WebGLRenderer, camera: Camera}) => {
          gl.setPixelRatio(Math.max(1, window.devicePixelRatio));
          gl.shadowMap.enabled = true;
          gl.shadowMap.type = PCFSoftShadowMap;
          camera.lookAt(-3, 4.2, 0);
        }}
      >
        <ambientLight intensity={1} color={new Color(0xFFFFFF)}/>

        <Suspense fallback={'Loading profile...'}>
          <BasicModel/>
        </Suspense>
      </MyCanvas>
      {/*<Controls/>*/}
    </Controls.Provider>
  );
};

export default Header3D;
