import CSS from 'csstype';
import { Link } from 'gatsby';
import React, { FunctionComponent } from 'react';
import { WpGraphQl_Post } from '../../../graphql-types';
import globalStyles from '../../styles/global.module.scss';
import { getPostUri } from '../../util/PostUtil';
import FadeInSection from '../fade-in-section/fade-in-section.component';
import styles from './post-card.component.module.scss';

type PostCardProps = {
  post: WpGraphQl_Post;
}

const PostCard: FunctionComponent<PostCardProps> = ({ post }) => {
  const featuredImageStyles: CSS.Properties = {
    backgroundImage: `url('${post.featuredImage?.node?.imageFile?.childImageSharp?.fixed?.src}')`
  };
  const content = {
    __html: `<h1>${post.title}</h1>${post.excerpt}`
  };

  return (
    <Link to={getPostUri(post)} className={styles.postCardLink}>
      <FadeInSection className={`${styles.postCard} ${globalStyles.scale}`}>
        <div className={styles.featuredImage} style={featuredImageStyles}/>
        <div className={styles.content} dangerouslySetInnerHTML={content}/>
      </FadeInSection>
    </Link>
  );
};

export default PostCard;