import React, { FunctionComponent, useState } from 'react';
import { useFrame, useLoader } from 'react-three-fiber';
import { MeshBasicMaterial, Vec2 } from 'three';
import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader';
import { useMousePosition } from '../../../hooks/useMousePosition';

const calculateRotation = function(mousePosition: Vec2): Vec2 {
  return {
    x: -mousePosition.y * 0.05,
    y: mousePosition.x * 0.2
  };
};

const BasicModel: FunctionComponent = () => {
  const scene = useLoader(ColladaLoader, '/models/logo_statue.dae');
  const material = new MeshBasicMaterial({
    // @ts-ignore
    map: scene.materials['objMesh_Merged_Material'].map,
    toneMapped: false
  });
  material.map!.anisotropy = 3;

  const [currentRotation, setCurrentRotation] = useState<Vec2>({ x: 0, y: 0 });

  const mousePosition = useMousePosition();

  useFrame((state) => {
    const normalizedMousePosition = { x: mousePosition.x / window.innerWidth, y: mousePosition.y / window.innerHeight };
    const targetRotation = calculateRotation(normalizedMousePosition);
    const velocity = {
      x: (targetRotation.x - currentRotation.x) * (1 - 0.8),
      y: (targetRotation.y - currentRotation.y) * (1 - 0.8)
    };
    const newRotation = {
      x: currentRotation.x + velocity.x,
      y: currentRotation.y + velocity.y
    };

    if (Math.abs(velocity.x) < 0.0001) {
      newRotation.x = targetRotation.x;
    }

    if (Math.abs(velocity.y) < 0.0001) {
      newRotation.y = targetRotation.y;
    }

    setCurrentRotation(newRotation);
  });

  return (
    // @ts-ignore
    <mesh rotation={[currentRotation.x, currentRotation.y - 1.57, 0]} geometry={scene.nodes.objMesh.geometry}
          material={material}/>
  );
};

export default BasicModel;
